/**
 * @file   src\containers\Login.js
 * @brief  This file is responsible for login page.
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../assets/styles/login.scss'
import logo from '../assets/img/login-logo.svg';
import loginimg from '../assets/img/login-illustration.svg';
import Strings from '../assets/strings/Strings.json';
import Input from '../components/Input';
import { _loginUser, _resetState } from '../actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { setVendor, removeVendor, getVendor } from '../utils/localStorage';
import viewpass from '../assets/img/view-password.svg'
import hidepass from '../assets/img/hide-password.svg'
import { resetResponse } from '../actions/VendorAction';
import * as alerts from '../utils/Messages';
import * as constants from '../utils/constants';

const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ------- call API from functions and response from redux start ---------
  const signInUser = (loginData) => dispatch(_loginUser(loginData));
  const signInResponse = useSelector((RootReducer) => RootReducer.AuthReducer.getAuthResponse)
  // ------- call API from functions and response from redux start ---------
  const resetState = () => dispatch(_resetState());

  const signoutResponse = useSelector((RootReducer) => RootReducer.AuthReducer.getSignoutResponse)

  // ------- state start -------
  const intitalValuesLoginData = {
    email: "",
    password: "",
    u_type: 2
  }

  const intitalformErrors = {
    email: "",
    password: ""
  }

  const [loginData, setLoginData] = useState(intitalValuesLoginData)
  const [signInResponseData, setSignInResponseData] = useState([])
  const [formErrors, setFormErrors] = useState(intitalformErrors)
  const [signInClicked, setSignInClicked] = useState(false)
  const [toastSuccessMessage, setToastSuccessMessage] = useState('')
  const [toastErrorMessage, setToastErrorMessage] = useState('')
  const [viewPassword, setViewPassword] = useState(false)
  const [load, setLoad] = useState(false)

  const refEmail = useRef(null);
  const refPassword = useRef(null);

  // ------- state end -------

  // ------- useEffect start -------

  useEffect(() => {
    if (signInClicked && signInResponse) {
      setSignInResponseData(signInResponse);
    }
  }, [signInResponse])



  useEffect(() => {
    if (toastSuccessMessage != '') {
      toast.success(toastSuccessMessage, {
        onClose: () => {
          setToastSuccessMessage('');
          setSignInResponseData([])
        }
      });
    }

  }, [toastSuccessMessage])

  useEffect(() => {
    if (toastErrorMessage != '') {
      toast.error(toastErrorMessage, {
        onClose: () => {
          setToastErrorMessage('')
          setSignInResponseData([])
        }
      });
    }
  }, [toastErrorMessage])



  useEffect(() => {
    if (signInResponseData) {
      setSignInClicked(false)

      if (signInResponseData.apiStatus && signInResponseData.apiErrorCode === constants.STATUS_200) {
        setLoad(false)
        // toast message
        setLoginData(intitalValuesLoginData)
        setToastSuccessMessage(signInResponseData.apiMessage)
        //response to localstorage

        if (signInResponseData.apiResponse && signInResponseData.apiResponse.user_info) {
          const vendorInfo = {
            a_token: signInResponseData.apiResponse.user_info.a_token,
            r_token: signInResponseData.apiResponse.user_info.r_token,
            f_name: signInResponseData.apiResponse.user_info.f_name,
            l_name: signInResponseData.apiResponse.user_info.l_name,
            e_time: signInResponseData.apiResponse.user_info.e_time
          };

          setVendor(JSON.stringify(vendorInfo));
          setTimeout(() => {
            if (signInResponseData.apiStatus)
              resetResponse(dispatch, 'signInResponseData')
            window.location.href = '/dashboard';
          }, 100)

        }
        else {
          setToastErrorMessage(signInResponseData.apiMessage)
        }

      } else if (signInResponseData && signInResponseData.apiResponse && signInResponseData.apiResponse.user_info) {
        setLoad(false)
        if (signInResponseData.apiResponse.user_info.vendor_id) {
          toast.error(signInResponseData.apiMessage)
          if (signInResponseData.apiResponse.user_info?.is_verified === constants.STATUS_1) {
            navigate("/register/step/3/" + (signInResponseData.apiResponse.user_info.vendor_id))
          } else {
            navigate("/register/step/2/" + (signInResponseData.apiResponse.user_info.vendor_id))
          }
        }
      }
      else {
        setLoad(false)
        setToastErrorMessage(signInResponseData.apiMessage)
      }

    }
  }, [signInResponseData])

  useEffect(() => {
    if (process.env.REACT_APP_USER_TYPE == 'vendor') {
      removeVendor();
      resetState();
    }
  }, [])

  // checks from successfully validated, if so called sign in function
  useEffect(() => {
    if (signInClicked) {
      if (formErrors.email == '' && formErrors.password == '') {
        signInUser(loginData);
      }
    }
  }, [formErrors, signInClicked])
  // ------- useEffect end -------

  // -------  custom functions start -------

  // update values to state
  const formChange = (event) => {
    setLoginData(loginData => ({ ...loginData, [event.target.name]: event.target.value }))
  }



  /**
 * login form validation
 * @param {json} loginData 
 * @returns json
 */

  const validateLogin = (loginData) => {
    let errors = { email: '', password: '' };
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let focusSet = false;
    if (!loginData.email) {
      setLoad(false)
      errors.email = alerts.ENTER_EMAIL;
      if (!focusSet) {
        refEmail.current.focus();
        focusSet = true;
      }
    } else if (!reg.test(loginData.email)) {
      setLoad(false)
      errors.email = alerts.ENTER_VALID_EMAIL;
      if (!focusSet) {
        refEmail.current.focus();
        focusSet = true;
      }
    }
    if (!loginData.password) {
      setLoad(false)
      errors.password = alerts.ENTER_PASSWORD;
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    }
    return errors;
  };


  // sign in button clicked
  const signIn = () => {
    setLoad(true)
    const response = validateLogin(loginData);
    //set validation response to state
    setFormErrors(response)
    // set flag sign in button clicked
    setSignInClicked(true)
  }
  // -------  custom functions end  -------
  const submitForm = (event) => {
    event.preventDefault();
    signIn();
  }

  const submitFormOnKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      signIn();
    }
  }
  return (
    <Container fluid className='login'>
      <Container className='login-outer'>
        <form onSubmit={submitForm}>
          <Row>
            <Col lg='6' className='d-flex align-items-center justify-content-center flex-column login-left'>
              <img src={logo} className='logo' alt='Shopvana Logo' />
              <img src={loginimg} className='login-img' alt="Shopvana Login" />
              <p className='text-center'>{Strings.Login.Tagline}</p>
            </Col>
            <Col lg='6'>
              <div className='login-wrap'>
                <h1>{Strings.Login.Title}</h1>
                <Row>
                  <Col lg='12'>
                    <Input
                      label={Strings.Login.Label1}
                      id='email'
                      name='email'
                      type='email'
                      onChange={formChange}
                      maxLength={200}
                      value={loginData.email}
                      errorMessage={formErrors.email ? formErrors.email : ""}
                      onKeyDown={submitFormOnKeyDown}
                      txtRef={refEmail}
                    />
                  </Col>
                  <span className='d-block text-end forgot-pass mb-0'><Link to='/forgotemail'>{Strings.Login.ForgotMail}</Link></span>
                  <Col lg='12' className='position-relative'>
                    <Input
                      label={Strings.Login.Label2}
                      id='password'
                      name='password'
                      type={viewPassword ? 'text' : 'password'}
                      onChange={formChange}
                      maxLength={15}
                      value={loginData.password}
                      errorMessage={formErrors.password ? formErrors.password : ""}
                      onKeyDown={submitFormOnKeyDown}
                      txtRef={refPassword}
                    />
                    <span className='view-password position-absolute'><img src={viewPassword && loginData.password !== "" ? viewpass : hidepass} onClick={() => setViewPassword(!viewPassword)} /></span>
                  </Col>
                </Row>
                <span className='d-block text-end forgot-pass'><Link to='/forgotpassword'>{Strings.Login.Forgot}</Link></span>
                <Button variant='primary' disabled={load} onClick={signIn}>{load ? <div class="spinner-border" style={{ width: '1.5rem', height: '1.5rem' }} role="status" /> : Strings.Login.Btn}</Button>
                <p className='reg-info mb-0'>{Strings.Login.RegInfo}<br /><Link to='/register'>{Strings.Login.RegLink}</Link></p>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </Container>
  );
}
export default Login;
